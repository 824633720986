// TODO: Generate this with a custom graphql-codegen plugin. Can just assume enum => String, scalar => jsonb
export const ExtensionToHasuraTypeMap: Record<string, undefined | string> = {
  breakout_type: 'String',
  breakouts_platform_settings: 'jsonb',
  channel_event: 'jsonb',
  channel_user_status_platform_states: 'jsonb',
  clipboard_prompt: 'jsonb',
  clubs_onboarding: 'jsonb',
  competitions_meta: 'jsonb',
  competitions_prize_meta: 'jsonb',
  feed_type: 'jsonb',
  feedback_source: 'String',
  notification_type: 'jsonb',
  otps_data: 'jsonb',
  projects_images: 'jsonb',
  projects_prize: 'jsonb',
  users_meta: 'jsonb',
  users_minecraft: 'jsonb',
  users_presence_meta: 'jsonb',
  channel_type: 'String',
  channel_user_role: 'String',
  channel_user_status: 'String',
  club_status: 'String',
  club_onboarding_requirement: 'jsonb',
  competition_status: 'String',
  comment_object_type: 'String',
  competition_type: 'String',
  feedback_sentiment: 'String',
  friendships_status: 'String',
  friendships_users_status: 'String',
  item_status: 'String',
  level_reward: 'jsonb',
  live_event_recurring: 'String',
  live_event_status: 'String',
  notification_target: 'String',
  otp_recipient_type: 'String',
  project_status: 'String',
  room_color: 'String',
  room_hosts: 'jsonb',
  room_kind: 'String',
  room_section_type: 'String',
  room_privacy: 'String',
  rooms_sections_platform_settings: 'jsonb',
  room_status: 'String',
  tag_type: 'String',
  theme_status: 'String',
  transaction_kind: 'String',
  tutorial_difficulty: 'String',
  user_action: 'jsonb',
  user_invite_kind: 'String',
  user_invite_status: 'String',
  user_invite_type: 'String',
  user_division: 'String',
  user_presence_platform: 'String',
  user_role: 'String',
  vote_object_type: 'String',
};
