// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type JoinBreakoutChannelMutationVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
  channelId: Types.Scalars['uuid']['input'];
  clubId: Types.Scalars['uuid']['input'];
  joinCall: Types.Scalars['Boolean']['input'];
  startMuted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type JoinBreakoutChannelMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly joinBreakout: (
    { readonly __typename?: 'JoinBreakoutOutput' }
    & Pick<Types.JoinBreakoutOutput, 'callAccessToken'>
  ) }
);



export const JoinBreakoutChannelDocument = `
    mutation JoinBreakoutChannel($roomId: uuid!, $channelId: uuid!, $clubId: uuid!, $joinCall: Boolean!, $startMuted: Boolean) {
  joinBreakout(
    roomId: $roomId
    channelId: $channelId
    clubId: $clubId
    joinCall: $joinCall
    startAudioOff: $startMuted
  ) {
    callAccessToken
  }
}
    `;

export const useJoinBreakoutChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<JoinBreakoutChannelMutation, TError, JoinBreakoutChannelMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<JoinBreakoutChannelMutation, TError, JoinBreakoutChannelMutationVariables, TContext>(
      ['JoinBreakoutChannel'],
      (variables?: JoinBreakoutChannelMutationVariables) => fetcher<JoinBreakoutChannelMutation, JoinBreakoutChannelMutationVariables>(client, JoinBreakoutChannelDocument, variables, headers)(),
      options
    )};

useJoinBreakoutChannelMutation.getKey = () => ['JoinBreakoutChannel'];


useJoinBreakoutChannelMutation.fetcher = (client: GraphQLClient, variables: JoinBreakoutChannelMutationVariables, headers?: RequestInit['headers']) => fetcher<JoinBreakoutChannelMutation, JoinBreakoutChannelMutationVariables>(client, JoinBreakoutChannelDocument, variables, headers);
