'use client';

import '@/app/globals.css';
import 'nprogress/nprogress.css';

// import { HighlightInit } from '@highlight-run/next/client';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Inter, JetBrains_Mono as JetbrainsMono } from 'next/font/google';
import Router from 'next/router';
import { ThemeProvider } from 'next-themes';
import NProgress from 'nprogress';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { type ComponentType, useRef } from 'react';

import type { AppProps } from '@/api/core/utils/page';
import { GraphQLSubscriptionClientProvider } from '@/client/core/providers/graphql-subscription-provider';
import { LogProvider } from '@/client/core/providers/logging-provider';
import { TooltipProvider } from '@/client/design-system/components/tooltip';
import { AlertProvider } from '@/client/features/alert/providers/alert-provider';
import { AnalyticsServiceProvider, useSegmentAnalyticsService } from '@/client/features/analytics';
import { AudioProvider } from '@/client/features/audio/providers/audio-provider';
import { ChannelUsersProvider } from '@/client/features/calls/operations/presence';
import { DialogProvider } from '@/client/features/dialog';
import { GoogleTagManagerRouteObserver } from '@/client/features/google-tag-manager/components';
import { IntercomProvider } from '@/client/features/intercom/intercom-provider';
import { ToastProvider } from '@/client/features/toast/providers/toast-provider';
import { UserPresenceProvider } from '@/client/features/user/providers';
import { UserProvider } from '@/client/features/user/providers/user-provider';
import { DefaultLayout } from '@/client/layouts/layout';
// import { CustomHighlightStart } from '@/components/highlight/highlight-start';
import MaintenanceView from '@/legacy/components/maintenanceView';
import { MAINTENANCE_MODE } from '@/legacy/lib/constants';

const interFont = Inter({
  variable: '--font-inter',
  subsets: ['latin'],
});

const jetbrainsMonoFont = JetbrainsMono({
  variable: '--font-jetbrains-mono',
  subsets: ['latin'],
});

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

if (typeof window !== 'undefined') {
  // checks that we are client-side
  if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: '/ingest',
      ui_host: 'https://us.i.posthog.com',
    });
  }
}

function App({ Component, pageProps: { activeClubUrlName, clubs, dehydratedState, session, ...pageProps } }: AppProps) {
  const segmentAnalyticsService = useSegmentAnalyticsService();
  const queryClientRef = useRef<QueryClient>(undefined);

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    });
  }
  if (MAINTENANCE_MODE) {
    return <MaintenanceView />;
  }
  const NestedLayout = Component.Layout ?? React.Fragment;
  // const highlightIoProjectId = process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID;

  return (
    <LogProvider>
      {/* <HighlightInit
        projectId={highlightIoProjectId}
        serviceName='ender-frontend'
        tracingOrigins
        excludedHostnames={['localhost']}
        networkRecording={{
          enabled: true,
          recordHeadersAndBody: true,
          urlBlocklist: [],
        }}
      /> */}
      {/* <CustomHighlightStart /> */}
      <GoogleTagManagerRouteObserver />
      <QueryClientProvider client={queryClientRef.current}>
        <AnalyticsServiceProvider value={segmentAnalyticsService}>
          <IntercomProvider>
            <GraphQLSubscriptionClientProvider>
              {(invalidateSubscriptionClient) => (
                <UserProvider
                  onUserIdChanged={invalidateSubscriptionClient}
                  onUserRoleChanged={invalidateSubscriptionClient}
                  user={session?.user}
                >
                  <ThemeProvider attribute='class' forcedTheme={Component.theme || undefined}>
                    <TooltipProvider delayDuration={100}>
                      <ToastProvider>
                        <AlertProvider>
                          <DialogProvider>
                            <Hydrate state={dehydratedState}>
                              <AudioProvider>
                                <UserPresenceProvider>
                                  <ChannelUsersProvider>
                                    <PostHogProvider client={posthog}>
                                      <DefaultLayout className={`${interFont.variable} ${jetbrainsMonoFont.variable}`}>
                                        <NestedLayout>
                                          <Component {...pageProps} />
                                        </NestedLayout>
                                      </DefaultLayout>
                                    </PostHogProvider>
                                  </ChannelUsersProvider>
                                </UserPresenceProvider>
                              </AudioProvider>
                            </Hydrate>
                          </DialogProvider>
                        </AlertProvider>
                      </ToastProvider>
                    </TooltipProvider>
                  </ThemeProvider>
                </UserProvider>
              )}
            </GraphQLSubscriptionClientProvider>
          </IntercomProvider>
        </AnalyticsServiceProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </LogProvider>
  );
}

// resolving ts errors
// see https://launchdarkly.com/blog/using-launchdarkly-with-typescript/
const WrappedApp = withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID!,
})(App as ComponentType);

export default WrappedApp;
