// Hasura client to make server side calls to hasura
// Supports both graphql request & query request

import { Logtail } from '@logtail/browser';

import { HASURA_ENDPOINT, isClient } from '../constants';

export interface HasuraScheduledEvent<Payload extends Record<string, unknown>> {
  webhook: string;
  time: string | number;
  comment: string;
  payload: Payload;
  deleteOld: boolean;
  oldComment?: string;
}

const HASURA_BASE_URL = {
  graphql: `${HASURA_ENDPOINT}/v1/graphql`,
  metadata: `${HASURA_ENDPOINT}/v1/metadata`,
};

export class Hasura {
  private static _instance: Hasura;

  private constructor() {}

  private static readonly logger =
    isClient && process.env.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN
      ? new Logtail(process.env.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN)
      : undefined;

  public static get Instance() {
    // Do you need arguments? Make it a regular static method instead.
    return this._instance || (this._instance = new this());
  }

  /**
   * @deprecated Use the new client-side Hasura GraphQL client instead.
   */
  public userRequest = async (
    query: string,
    variables: null | any = null,
    operation: null | string = null,
    headers: null | any = null
  ) => {
    const reqHeaders: any = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    if (headers) {
      for (const key in headers) {
        reqHeaders[key] = headers[key];
      }
    }
    try {
      const res = await fetch(HASURA_BASE_URL.graphql, {
        method: 'POST',
        headers: reqHeaders,
        credentials: 'include',
        body: JSON.stringify({ query, variables, operation }),
      });
      const { data, errors } = await res.json();
      if (errors) {
        console.log(errors);
      }

      return { data, errors };
    } catch (error: any) {
      console.log(error);
      if (Hasura.logger) {
        Hasura.logger.log(error);
      }
      throw error;
    }
  };

  /**
   * @deprecated Use the new server-side Hasura GraphQL client instead.
   */
  public graphqlRequest = async (query: string, variables: null | any = null, operation: null | string = null) => {
    const { data, errors } = await this.request({ query, variables, operation }, HASURA_BASE_URL.graphql);
    if (errors) {
      console.log('admin: graphql request failure', errors);
      return await Promise.reject(errors);
    }

    return { data, errors };
  };

  public metadataRequest = async (query: any) => await this.request(query, HASURA_BASE_URL.metadata);

  public scheduleEvent = async (event: HasuraScheduledEvent<any>, token: string) => {
    // delete existing scheduled job if any
    if (event.deleteOld) {
      await this.deleteScheduledEvent(event.comment, event.oldComment);
    }

    const query = this.scheduleEventQuery(event, token);
    return await this.metadataRequest(query);
  };

  private readonly deleteScheduledEvent = async (comment: string, oldComment: string | undefined) => {
    const eventIds = await this.getScheduledEventIds(comment, oldComment);

    if (eventIds.length === 0) {
      return;
    }
    const query = this.bulkQuery(eventIds.map((id) => this.deleteScheduledEventQuery(id)));

    console.log(eventIds);

    return await this.metadataRequest(query);
  };

  public deleteScheduledEventById = async (id: string) => {
    const query = this.deleteScheduledEventQuery(id);

    return await this.metadataRequest(query);
  };

  private readonly request = async (query: any, url: string) => {
    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'x-hasura-admin-secret': process.env.HASURA_GRAPHQL_ADMIN_SECRET!, // This env variable is only accessible via server
        },
        body: JSON.stringify(query),
      });

      return res.json();
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };

  private readonly scheduleEventQuery = (event: HasuraScheduledEvent<any>, token: string) => ({
    type: 'create_scheduled_event',
    args: {
      webhook: `{{APPLICATION_BASE_URL}}/${event.webhook}`,
      schedule_at: new Date(event.time).toISOString(),
      payload: event.payload,
      headers: [
        {
          name: 'Content-Type',
          value: 'application/json',
        },
        {
          name: 'Accept',
          value: 'application/json',
        },
        {
          name: 'x-hasura-admin-secret',
          value: process.env.HASURA_GRAPHQL_ADMIN_SECRET,
        },
        {
          name: 'api-token',
          value: token,
        },
      ],
      retry_conf: {
        num_retries: 3,
      },
      comment: event.comment,
    },
  });

  private readonly deleteScheduledEventQuery = (eventId: string) => ({
    type: 'delete_scheduled_event',
    args: {
      type: 'one_off',
      event_id: eventId,
    },
  });

  private readonly getScheduledEventIds = async (comment: string, oldComment: string | undefined) => {
    const query = {
      type: 'get_scheduled_events',
      args: {
        type: 'one_off',
        status: ['scheduled'],
      },
    };
    const { events } = await this.metadataRequest(query);

    return events.filter((e: any) => e.comment === comment || e.comment === oldComment).map((e: any) => e.id) as string[];
  };

  private readonly bulkQuery = (queries: object[]) => ({
    type: 'bulk_keep_going',
    args: queries,
  });
}

export default Hasura.Instance;
