// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, useMutation, type UseQueryOptions, type UseInfiniteQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type ChannelsUserParticipantFragment = (
  { readonly __typename?: 'channels_user' }
  & Pick<Types.Channels_User, 'active_channel_id' | 'user_id' | 'hand_raised' | 'hand_raised_updated_at' | 'connection' | 'volume' | 'force_muted'>
  & { readonly public_user?: Types.Maybe<(
    { readonly __typename?: 'public_users' }
    & Pick<Types.Public_Users, 'username' | 'photo' | 'role'>
  )> }
);

export type GetActiveChannelUsersQueryVariables = Types.Exact<{
  channelIds: ReadonlyArray<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
}>;


export type GetActiveChannelUsersQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly channels_user: ReadonlyArray<(
    { readonly __typename?: 'channels_user' }
    & Pick<Types.Channels_User, 'id' | 'user_id' | 'hand_raised' | 'hand_raised_updated_at' | 'force_muted' | 'connection' | 'volume' | 'active_channel_updated_at'>
    & { readonly public_user?: Types.Maybe<(
      { readonly __typename?: 'public_users' }
      & Pick<Types.Public_Users, 'id' | 'username' | 'photo' | 'role'>
    )>, readonly active_channel?: Types.Maybe<(
      { readonly __typename?: 'channels' }
      & Pick<Types.Channels, 'id'>
      & { readonly breakout?: Types.Maybe<(
        { readonly __typename?: 'breakouts' }
        & Pick<Types.Breakouts, 'id'>
      )> }
    )> }
  )> }
);

export type RefreshChannelsUserHeartbeatMutationVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
  now: Types.Scalars['timestamptz']['input'];
}>;


export type RefreshChannelsUserHeartbeatMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly update_channels_user?: Types.Maybe<(
    { readonly __typename?: 'channels_user_mutation_response' }
    & Pick<Types.Channels_User_Mutation_Response, 'affected_rows'>
  )> }
);

export type UnsetActiveChannelIdMutationVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
}>;


export type UnsetActiveChannelIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly update_channels_user?: Types.Maybe<(
    { readonly __typename?: 'channels_user_mutation_response' }
    & Pick<Types.Channels_User_Mutation_Response, 'affected_rows'>
  )> }
);

export type SetActiveChannelIdMutationVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
}>;


export type SetActiveChannelIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly update_channels_user?: Types.Maybe<(
    { readonly __typename?: 'channels_user_mutation_response' }
    & Pick<Types.Channels_User_Mutation_Response, 'affected_rows'>
  )> }
);

export type CreateSelfChannelsUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;


export type CreateSelfChannelsUserMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly insert_channels_user_one?: Types.Maybe<(
    { readonly __typename?: 'channels_user' }
    & Pick<Types.Channels_User, 'id'>
  )> }
);


export const ChannelsUserParticipantFragmentDoc = `
    fragment ChannelsUserParticipant on channels_user {
  active_channel_id
  user_id
  hand_raised
  hand_raised_updated_at
  connection
  volume
  force_muted
  public_user {
    username
    photo
    role
  }
}
    `;
export const GetActiveChannelUsersDocument = `
    query GetActiveChannelUsers($channelIds: [uuid!]!) {
  channels_user(
    where: {active_channel_id: {_in: $channelIds}}
    order_by: {user: {username: asc}}
  ) {
    id
    user_id
    hand_raised
    hand_raised_updated_at
    force_muted
    connection
    volume
    public_user {
      id
      username
      photo
      role
    }
    active_channel {
      id
      breakout {
        id
      }
    }
    active_channel_updated_at
  }
}
    `;

export const useGetActiveChannelUsersQuery = <
      TData = GetActiveChannelUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetActiveChannelUsersQueryVariables,
      options?: UseQueryOptions<GetActiveChannelUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetActiveChannelUsersQuery, TError, TData>(
      ['GetActiveChannelUsers', variables],
      fetcher<GetActiveChannelUsersQuery, GetActiveChannelUsersQueryVariables>(client, GetActiveChannelUsersDocument, variables, headers),
      options
    )};

useGetActiveChannelUsersQuery.getKey = (variables: GetActiveChannelUsersQueryVariables) => ['GetActiveChannelUsers', variables];

export const useInfiniteGetActiveChannelUsersQuery = <
      TData = GetActiveChannelUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetActiveChannelUsersQueryVariables,
      options?: UseInfiniteQueryOptions<GetActiveChannelUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetActiveChannelUsersQuery, TError, TData>(
      ['GetActiveChannelUsers.infinite', variables],
      (metaData) => fetcher<GetActiveChannelUsersQuery, GetActiveChannelUsersQueryVariables>(client, GetActiveChannelUsersDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetActiveChannelUsersQuery.getKey = (variables: GetActiveChannelUsersQueryVariables) => ['GetActiveChannelUsers.infinite', variables];


useGetActiveChannelUsersQuery.fetcher = (client: GraphQLClient, variables: GetActiveChannelUsersQueryVariables, headers?: RequestInit['headers']) => fetcher<GetActiveChannelUsersQuery, GetActiveChannelUsersQueryVariables>(client, GetActiveChannelUsersDocument, variables, headers);

export const RefreshChannelsUserHeartbeatDocument = `
    mutation RefreshChannelsUserHeartbeat($channelId: uuid!, $now: timestamptz!) {
  update_channels_user(
    where: {active_channel_id: {_eq: $channelId}, user: {current: {_eq: true}}}
    _set: {updated_at: $now}
  ) {
    affected_rows
  }
}
    `;

export const useRefreshChannelsUserHeartbeatMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RefreshChannelsUserHeartbeatMutation, TError, RefreshChannelsUserHeartbeatMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<RefreshChannelsUserHeartbeatMutation, TError, RefreshChannelsUserHeartbeatMutationVariables, TContext>(
      ['RefreshChannelsUserHeartbeat'],
      (variables?: RefreshChannelsUserHeartbeatMutationVariables) => fetcher<RefreshChannelsUserHeartbeatMutation, RefreshChannelsUserHeartbeatMutationVariables>(client, RefreshChannelsUserHeartbeatDocument, variables, headers)(),
      options
    )};

useRefreshChannelsUserHeartbeatMutation.getKey = () => ['RefreshChannelsUserHeartbeat'];


useRefreshChannelsUserHeartbeatMutation.fetcher = (client: GraphQLClient, variables: RefreshChannelsUserHeartbeatMutationVariables, headers?: RequestInit['headers']) => fetcher<RefreshChannelsUserHeartbeatMutation, RefreshChannelsUserHeartbeatMutationVariables>(client, RefreshChannelsUserHeartbeatDocument, variables, headers);

export const UnsetActiveChannelIdDocument = `
    mutation UnsetActiveChannelId($channelId: uuid!) {
  update_channels_user(
    where: {user: {current: {_eq: true}}, active_channel_id: {_eq: $channelId}}
    _set: {active_channel_id: null}
  ) {
    affected_rows
  }
}
    `;

export const useUnsetActiveChannelIdMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UnsetActiveChannelIdMutation, TError, UnsetActiveChannelIdMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UnsetActiveChannelIdMutation, TError, UnsetActiveChannelIdMutationVariables, TContext>(
      ['UnsetActiveChannelId'],
      (variables?: UnsetActiveChannelIdMutationVariables) => fetcher<UnsetActiveChannelIdMutation, UnsetActiveChannelIdMutationVariables>(client, UnsetActiveChannelIdDocument, variables, headers)(),
      options
    )};

useUnsetActiveChannelIdMutation.getKey = () => ['UnsetActiveChannelId'];


useUnsetActiveChannelIdMutation.fetcher = (client: GraphQLClient, variables: UnsetActiveChannelIdMutationVariables, headers?: RequestInit['headers']) => fetcher<UnsetActiveChannelIdMutation, UnsetActiveChannelIdMutationVariables>(client, UnsetActiveChannelIdDocument, variables, headers);

export const SetActiveChannelIdDocument = `
    mutation SetActiveChannelId($channelId: uuid!) {
  update_channels_user(
    where: {user: {current: {_eq: true}}}
    _set: {active_channel_id: $channelId}
  ) {
    affected_rows
  }
}
    `;

export const useSetActiveChannelIdMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SetActiveChannelIdMutation, TError, SetActiveChannelIdMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SetActiveChannelIdMutation, TError, SetActiveChannelIdMutationVariables, TContext>(
      ['SetActiveChannelId'],
      (variables?: SetActiveChannelIdMutationVariables) => fetcher<SetActiveChannelIdMutation, SetActiveChannelIdMutationVariables>(client, SetActiveChannelIdDocument, variables, headers)(),
      options
    )};

useSetActiveChannelIdMutation.getKey = () => ['SetActiveChannelId'];


useSetActiveChannelIdMutation.fetcher = (client: GraphQLClient, variables: SetActiveChannelIdMutationVariables, headers?: RequestInit['headers']) => fetcher<SetActiveChannelIdMutation, SetActiveChannelIdMutationVariables>(client, SetActiveChannelIdDocument, variables, headers);

export const CreateSelfChannelsUserDocument = `
    mutation CreateSelfChannelsUser($userId: String!) {
  insert_channels_user_one(
    object: {user_id: $userId}
    on_conflict: {constraint: channels_user_user_id_key, update_columns: []}
  ) {
    id
  }
}
    `;

export const useCreateSelfChannelsUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateSelfChannelsUserMutation, TError, CreateSelfChannelsUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CreateSelfChannelsUserMutation, TError, CreateSelfChannelsUserMutationVariables, TContext>(
      ['CreateSelfChannelsUser'],
      (variables?: CreateSelfChannelsUserMutationVariables) => fetcher<CreateSelfChannelsUserMutation, CreateSelfChannelsUserMutationVariables>(client, CreateSelfChannelsUserDocument, variables, headers)(),
      options
    )};

useCreateSelfChannelsUserMutation.getKey = () => ['CreateSelfChannelsUser'];


useCreateSelfChannelsUserMutation.fetcher = (client: GraphQLClient, variables: CreateSelfChannelsUserMutationVariables, headers?: RequestInit['headers']) => fetcher<CreateSelfChannelsUserMutation, CreateSelfChannelsUserMutationVariables>(client, CreateSelfChannelsUserDocument, variables, headers);
