// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { BreakoutDetailsFragmentDoc } from '../../breakouts/generated/operations.user';
import { useQuery, useInfiniteQuery, useMutation, type UseQueryOptions, type UseInfiniteQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type RoomDetailFragment = (
  { readonly __typename?: 'rooms' }
  & Pick<Types.Rooms, 'association_id' | 'color' | 'id' | 'kind' | 'name' | 'status' | 'tagline' | 'url_name' | 'owner_id' | 'lock_time' | 'unlock_time' | 'feedback_question' | 'clipboard_prompt' | 'sort_order' | 'icon_url' | 'host_user_ids' | 'privacy' | 'club_id'>
  & { readonly club: (
    { readonly __typename?: 'clubs' }
    & Pick<Types.Clubs, 'id' | 'name' | 'url_name' | 'display_settings'>
    & { readonly members: ReadonlyArray<(
      { readonly __typename?: 'clubs_members' }
      & Pick<Types.Clubs_Members, 'onboarded'>
    )> }
  ), readonly channel?: Types.Maybe<(
    { readonly __typename?: 'channels' }
    & Pick<Types.Channels, 'id' | 'association_id' | 'user_status_platform_states'>
  )>, readonly breakouts: ReadonlyArray<(
    { readonly __typename?: 'breakouts' }
    & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'room_id' | 'channel_id'>
    & { readonly room?: Types.Maybe<(
      { readonly __typename?: 'rooms' }
      & Pick<Types.Rooms, 'club_id' | 'kind'>
    )> }
  )>, readonly rooms_schedule?: Types.Maybe<(
    { readonly __typename?: 'schedules' }
    & Pick<Types.Schedules, 'id' | 'timezone' | 'schedule'>
  )> }
);

export type GetRoomHostIdsQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
}>;


export type GetRoomHostIdsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms_by_pk?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'host_user_ids'>
  )> }
);

export type ToggleRoomHostIdMutationVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type ToggleRoomHostIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly toggleRoomHostId: (
    { readonly __typename?: 'ToggleRoomHostIdOutput' }
    & Pick<Types.ToggleRoomHostIdOutput, 'success' | 'room_host_user_ids'>
  ) }
);

export type GetRoomByBreakoutIdQueryVariables = Types.Exact<{
  breakoutId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type GetRoomByBreakoutIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms: ReadonlyArray<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'association_id' | 'color' | 'id' | 'kind' | 'name' | 'status' | 'tagline' | 'url_name' | 'owner_id' | 'lock_time' | 'unlock_time' | 'feedback_question' | 'clipboard_prompt' | 'sort_order' | 'icon_url' | 'host_user_ids' | 'privacy' | 'club_id'>
    & { readonly club: (
      { readonly __typename?: 'clubs' }
      & Pick<Types.Clubs, 'id' | 'name' | 'url_name' | 'display_settings'>
      & { readonly members: ReadonlyArray<(
        { readonly __typename?: 'clubs_members' }
        & Pick<Types.Clubs_Members, 'onboarded'>
      )> }
    ), readonly channel?: Types.Maybe<(
      { readonly __typename?: 'channels' }
      & Pick<Types.Channels, 'id' | 'association_id' | 'user_status_platform_states'>
    )>, readonly breakouts: ReadonlyArray<(
      { readonly __typename?: 'breakouts' }
      & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'room_id' | 'channel_id'>
      & { readonly room?: Types.Maybe<(
        { readonly __typename?: 'rooms' }
        & Pick<Types.Rooms, 'club_id' | 'kind'>
      )> }
    )>, readonly rooms_schedule?: Types.Maybe<(
      { readonly __typename?: 'schedules' }
      & Pick<Types.Schedules, 'id' | 'timezone' | 'schedule'>
    )> }
  )> }
);


export const RoomDetailFragmentDoc = `
    fragment RoomDetail on rooms {
  association_id
  color
  id
  kind
  name
  status
  tagline
  url_name
  owner_id
  lock_time
  unlock_time
  feedback_question
  clipboard_prompt
  sort_order
  icon_url
  host_user_ids
  privacy
  club_id
  club {
    id
    name
    url_name
    display_settings
    members(where: {user_id: {_eq: $userId}}) {
      onboarded
    }
  }
  channel {
    id
    association_id
    user_status_platform_states
  }
  breakouts(order_by: {sort_order: asc, created_at: asc}) {
    ...BreakoutDetails
  }
  rooms_schedule {
    id
    timezone
    schedule
  }
}
    ${BreakoutDetailsFragmentDoc}`;
export const GetRoomHostIdsDocument = `
    query GetRoomHostIds($roomId: uuid!) {
  rooms_by_pk(id: $roomId) {
    host_user_ids
  }
}
    `;

export const useGetRoomHostIdsQuery = <
      TData = GetRoomHostIdsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomHostIdsQueryVariables,
      options?: UseQueryOptions<GetRoomHostIdsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRoomHostIdsQuery, TError, TData>(
      ['GetRoomHostIds', variables],
      fetcher<GetRoomHostIdsQuery, GetRoomHostIdsQueryVariables>(client, GetRoomHostIdsDocument, variables, headers),
      options
    )};

useGetRoomHostIdsQuery.getKey = (variables: GetRoomHostIdsQueryVariables) => ['GetRoomHostIds', variables];

export const useInfiniteGetRoomHostIdsQuery = <
      TData = GetRoomHostIdsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomHostIdsQueryVariables,
      options?: UseInfiniteQueryOptions<GetRoomHostIdsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetRoomHostIdsQuery, TError, TData>(
      ['GetRoomHostIds.infinite', variables],
      (metaData) => fetcher<GetRoomHostIdsQuery, GetRoomHostIdsQueryVariables>(client, GetRoomHostIdsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetRoomHostIdsQuery.getKey = (variables: GetRoomHostIdsQueryVariables) => ['GetRoomHostIds.infinite', variables];


useGetRoomHostIdsQuery.fetcher = (client: GraphQLClient, variables: GetRoomHostIdsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRoomHostIdsQuery, GetRoomHostIdsQueryVariables>(client, GetRoomHostIdsDocument, variables, headers);

export const ToggleRoomHostIdDocument = `
    mutation ToggleRoomHostId($roomId: uuid!, $userId: String!) {
  toggleRoomHostId(roomId: $roomId, userId: $userId) {
    success
    room_host_user_ids
  }
}
    `;

export const useToggleRoomHostIdMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ToggleRoomHostIdMutation, TError, ToggleRoomHostIdMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<ToggleRoomHostIdMutation, TError, ToggleRoomHostIdMutationVariables, TContext>(
      ['ToggleRoomHostId'],
      (variables?: ToggleRoomHostIdMutationVariables) => fetcher<ToggleRoomHostIdMutation, ToggleRoomHostIdMutationVariables>(client, ToggleRoomHostIdDocument, variables, headers)(),
      options
    )};

useToggleRoomHostIdMutation.getKey = () => ['ToggleRoomHostId'];


useToggleRoomHostIdMutation.fetcher = (client: GraphQLClient, variables: ToggleRoomHostIdMutationVariables, headers?: RequestInit['headers']) => fetcher<ToggleRoomHostIdMutation, ToggleRoomHostIdMutationVariables>(client, ToggleRoomHostIdDocument, variables, headers);

export const GetRoomByBreakoutIdDocument = `
    query GetRoomByBreakoutId($breakoutId: uuid!, $userId: String!) {
  rooms(where: {breakouts: {id: {_eq: $breakoutId}}}, limit: 1) {
    ...RoomDetail
  }
}
    ${RoomDetailFragmentDoc}`;

export const useGetRoomByBreakoutIdQuery = <
      TData = GetRoomByBreakoutIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomByBreakoutIdQueryVariables,
      options?: UseQueryOptions<GetRoomByBreakoutIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRoomByBreakoutIdQuery, TError, TData>(
      ['GetRoomByBreakoutId', variables],
      fetcher<GetRoomByBreakoutIdQuery, GetRoomByBreakoutIdQueryVariables>(client, GetRoomByBreakoutIdDocument, variables, headers),
      options
    )};

useGetRoomByBreakoutIdQuery.getKey = (variables: GetRoomByBreakoutIdQueryVariables) => ['GetRoomByBreakoutId', variables];

export const useInfiniteGetRoomByBreakoutIdQuery = <
      TData = GetRoomByBreakoutIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomByBreakoutIdQueryVariables,
      options?: UseInfiniteQueryOptions<GetRoomByBreakoutIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetRoomByBreakoutIdQuery, TError, TData>(
      ['GetRoomByBreakoutId.infinite', variables],
      (metaData) => fetcher<GetRoomByBreakoutIdQuery, GetRoomByBreakoutIdQueryVariables>(client, GetRoomByBreakoutIdDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetRoomByBreakoutIdQuery.getKey = (variables: GetRoomByBreakoutIdQueryVariables) => ['GetRoomByBreakoutId.infinite', variables];


useGetRoomByBreakoutIdQuery.fetcher = (client: GraphQLClient, variables: GetRoomByBreakoutIdQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRoomByBreakoutIdQuery, GetRoomByBreakoutIdQueryVariables>(client, GetRoomByBreakoutIdDocument, variables, headers);
