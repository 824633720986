import type { JSX, ReactNode } from 'react';
import { useIntervalWhen } from 'rooks';

import { useUpdateSelfUserPresenceMutation } from '@/client/api/user_presence/generated/operations.user';
import { useUserPresence } from '@/client/features/user/hooks';
import { useUser } from '@/hooks/use-user';
import hasuraGraphQLClient from '@/shared/graphql/client';
import { UserPresencePlatform } from '@/shared/graphql/schema-extensions/scalars/user-presence-platform';

interface Props {
  children: ReactNode;
}

const HEARTBEAT_MS = 3000;

export const UserPresenceProvider = ({ children }: Props): JSX.Element => {
  const { user } = useUser();

  const presence = useUserPresence(user?.id);
  const { mutateAsync: updateSelfUserPresence } = useUpdateSelfUserPresenceMutation(hasuraGraphQLClient.Client);

  useIntervalWhen(
    () => {
      const { error, loading, web } = presence;

      if (!error && !loading && web && user) {
        updateSelfUserPresence({
          userId: user.id,
          platform: UserPresencePlatform.Web,
          online: web.online,
        });
      }
    },
    HEARTBEAT_MS,
    user && presence.web !== undefined,
  );

  return <>{children}</>;
};
