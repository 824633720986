import { type DocumentNode, parse } from 'graphql/language';
import type { TypeNode } from 'graphql/language/ast';
import { GraphQLClient, type RequestDocument, type RequestOptions, type Variables } from 'graphql-request';
import type { RequestConfig } from 'graphql-request/build/esm/types';

import { HASURA_ENDPOINT } from '@/legacy/lib/constants';
import { ExtensionToHasuraTypeMap } from '@/shared/graphql/types';
import { ExhaustiveCheckError } from '@/shared/utils/correctness';

const HASURA_GRAPHQL_ENDPOINT = `${HASURA_ENDPOINT}/v1/graphql`;

// Only accessible from server side requests
const ADMIN_SECRET = `${process.env.HASURA_GRAPHQL_ADMIN_SECRET}`;

type OptionsExtender = (options: RequestInit) => RequestInit;

function extensionToHasuraType<T extends TypeNode>(typeNode: T): T;
function extensionToHasuraType(typeNode: TypeNode): TypeNode {
  switch (typeNode.kind) {
    case 'NamedType':
      return {
        ...typeNode,
        name: {
          ...typeNode.name,
          value: ExtensionToHasuraTypeMap[typeNode.name.value] ?? typeNode.name.value,
        },
      };

    case 'ListType':
      return {
        ...typeNode,
        type: extensionToHasuraType(typeNode.type),
      };

    case 'NonNullType':
      return {
        ...typeNode,
        type: extensionToHasuraType(typeNode.type),
      };

    default:
      throw new ExhaustiveCheckError(typeNode);
  }
}

const extensionToHasuraDocument = (documentNode: DocumentNode) => ({
  ...documentNode,
  definitions: documentNode.definitions.map((definition) => {
    if (definition.kind === 'OperationDefinition') {
      return {
        ...definition,
        variableDefinitions: definition.variableDefinitions?.map((variable) => ({
          ...variable,
          type: extensionToHasuraType(variable.type),
        })),
      };
    }

    return definition;
  }),
});

// TODO: ENDER-238: Update our GraphQL codegen to wrap _DOCUMENT definitions in extensionToHasuraDocument. Then:
// 1. We don't need to monkey patch GraphQLClient anymore.
// 2. We'll cover *all* GraphQLClient methods. If someone tries to use rawRequest etc. right now we're in trouble.
// 3. It'll be more performant, since the parsing/conversion need only happen once.
// Note: Technically we could run extensionToHasuraDocument during codegen, which will be even more performant, but
//       may be a headache to handle given some plugins use gql`` syntax and others strings.
function extensionToHasuraRequest<T = any>(
  document: RequestDocument,
  variables?: Variables,
  requestHeaders?: RequestInit['headers']
): Promise<T>;
function extensionToHasuraRequest<T = any, V extends Variables = Variables>(options: RequestOptions<V>): Promise<T>;
async function extensionToHasuraRequest<T = any, V extends Variables = Variables>(
  this: GraphQLClient,
  documentOrOptions: RequestDocument | RequestOptions<V>,
  variables?: V,
  requestHeaders?: RequestInit['headers'],
): Promise<T> {
  const document =
    typeof documentOrOptions === 'object' && 'document' in documentOrOptions
      ? documentOrOptions.document
      : documentOrOptions;

  const hasuraDocument = extensionToHasuraDocument(typeof document === 'string' ? parse(document) : document);
  const hasuraDocumentOrOptions =
    typeof documentOrOptions === 'object' && 'document' in documentOrOptions
      ? {
        ...documentOrOptions,
        document: hasuraDocument,
      }
      : hasuraDocument;

  return await (
    GraphQLClient.prototype.request as (
      this: GraphQLClient,
      documentOrOptions: RequestDocument | RequestOptions<V>,
      variables?: V,
      requestHeaders?: RequestInit['headers']
    ) => Promise<T>
  ).call(this, hasuraDocumentOrOptions, variables, requestHeaders);
}

export const createHasuraGraphQLClient = (extendOptions: OptionsExtender = (options) => options): GraphQLClient => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  const options = extendOptions({
    method: 'POST',
    headers,
  });

  const client = new GraphQLClient(HASURA_GRAPHQL_ENDPOINT, options as RequestConfig);
  client.request = extensionToHasuraRequest;
  return client;
};

const AdminClient = createHasuraGraphQLClient((options) => {
  const headers = new Headers(options.headers);
  headers.append('x-hasura-admin-secret', ADMIN_SECRET);

  return { ...options, headers };
});

export const ClientSideClient = createHasuraGraphQLClient((options) => ({ ...options, credentials: 'include' }));

const hasuraGraphQLClient = {
  Client: ClientSideClient,
  Admin: AdminClient,
};

type Fetcher<A extends any[], R> = (client: GraphQLClient, ...args: A) => () => R;

export function wrapFetcher<F extends Fetcher<any[], any>>(
  fetcher: F,
  client: GraphQLClient = hasuraGraphQLClient.Client,
): F extends Fetcher<infer A, infer R> ? (...args: A) => R : never {
  const wrappedFetcher = (...args: any[]) => fetcher(client, ...args)();
  return wrappedFetcher as any;
}

export default hasuraGraphQLClient;
