// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type ClubDetailFragment = (
  { readonly __typename?: 'clubs' }
  & Pick<Types.Clubs, 'id' | 'name' | 'status' | 'url_name' | 'display_settings' | 'privacy' | 'lock_time' | 'unlock_time' | 'onboarding_requirements'>
  & { readonly schedule?: Types.Maybe<(
    { readonly __typename?: 'schedules' }
    & Pick<Types.Schedules, 'id' | 'timezone' | 'schedule'>
  )>, readonly membership: ReadonlyArray<(
    { readonly __typename?: 'clubs_members' }
    & Pick<Types.Clubs_Members, 'onboarded' | 'onboarding'>
  )> }
);

export type UpsertClubMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
  privacy: Types.Scalars['String']['input'];
  displaySettings?: Types.InputMaybe<Types.Scalars['jsonb']['input']>;
}>;


export type UpsertClubMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly upsertClub?: Types.Maybe<(
    { readonly __typename?: 'UpsertClubOutput' }
    & Pick<Types.UpsertClubOutput, 'success'>
    & { readonly errors?: Types.Maybe<(
      { readonly __typename?: 'UpsertClubOutputErrors' }
      & Pick<Types.UpsertClubOutputErrors, 'id' | 'backgroundImage' | 'navigationIcon' | 'navigationColor' | 'navigationImage' | 'name' | 'privacy'>
    )> }
  )> }
);


export const ClubDetailFragmentDoc = `
    fragment ClubDetail on clubs {
  id
  name
  status
  url_name
  display_settings
  privacy
  lock_time
  unlock_time
  onboarding_requirements
  schedule {
    id
    timezone
    schedule
  }
  membership: members(where: {user_id: {_eq: $userId}}) {
    onboarded
    onboarding
  }
}
    `;
export const UpsertClubDocument = `
    mutation upsertClub($id: uuid!, $name: String!, $privacy: String!, $displaySettings: jsonb) {
  upsertClub(
    id: $id
    name: $name
    privacy: $privacy
    displaySettings: $displaySettings
  ) {
    success
    errors {
      id
      backgroundImage
      navigationIcon
      navigationColor
      navigationImage
      name
      privacy
    }
  }
}
    `;

export const useUpsertClubMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpsertClubMutation, TError, UpsertClubMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpsertClubMutation, TError, UpsertClubMutationVariables, TContext>(
      ['upsertClub'],
      (variables?: UpsertClubMutationVariables) => fetcher<UpsertClubMutation, UpsertClubMutationVariables>(client, UpsertClubDocument, variables, headers)(),
      options
    )};

useUpsertClubMutation.getKey = () => ['upsertClub'];


useUpsertClubMutation.fetcher = (client: GraphQLClient, variables: UpsertClubMutationVariables, headers?: RequestInit['headers']) => fetcher<UpsertClubMutation, UpsertClubMutationVariables>(client, UpsertClubDocument, variables, headers);
