export default class Environment {
  static isDevelopment(): boolean {
    return Boolean(
      process.env.NODE_ENV === 'development' ||
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ||
        process.env.VERCEL_ENV === 'development' ||
        process.env.VERCEL_ENV === 'preview' ||
        process.env.NEXT_PUBLIC_NODE_ENV === 'development' ||
        process.env.NEXT_PUBLIC_NODE_ENV === 'preview',
    );
  }

  static isClient(): boolean {
    return typeof window !== 'undefined';
  }
}
