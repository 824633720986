// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetChannelUsersQueryVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
}>;


export type GetChannelUsersQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly channels_user: ReadonlyArray<(
    { readonly __typename?: 'channels_user' }
    & Pick<Types.Channels_User, 'user_id' | 'hand_raised' | 'force_muted' | 'connection'>
    & { readonly profile?: Types.Maybe<(
      { readonly __typename?: 'public_users' }
      & Pick<Types.Public_Users, 'photo' | 'username' | 'role'>
    )> }
  )> }
);



export const GetChannelUsersDocument = `
    query GetChannelUsers($channelId: uuid!) {
  channels_user(where: {active_channel_id: {_eq: $channelId}}) {
    user_id
    hand_raised
    force_muted
    connection
    profile: public_user {
      photo
      username
      role
    }
  }
}
    `;

export const useGetChannelUsersQuery = <
      TData = GetChannelUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetChannelUsersQueryVariables,
      options?: UseQueryOptions<GetChannelUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetChannelUsersQuery, TError, TData>(
      ['GetChannelUsers', variables],
      fetcher<GetChannelUsersQuery, GetChannelUsersQueryVariables>(client, GetChannelUsersDocument, variables, headers),
      options
    )};

useGetChannelUsersQuery.getKey = (variables: GetChannelUsersQueryVariables) => ['GetChannelUsers', variables];

export const useInfiniteGetChannelUsersQuery = <
      TData = GetChannelUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetChannelUsersQueryVariables,
      options?: UseInfiniteQueryOptions<GetChannelUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetChannelUsersQuery, TError, TData>(
      ['GetChannelUsers.infinite', variables],
      (metaData) => fetcher<GetChannelUsersQuery, GetChannelUsersQueryVariables>(client, GetChannelUsersDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetChannelUsersQuery.getKey = (variables: GetChannelUsersQueryVariables) => ['GetChannelUsers.infinite', variables];


useGetChannelUsersQuery.fetcher = (client: GraphQLClient, variables: GetChannelUsersQueryVariables, headers?: RequestInit['headers']) => fetcher<GetChannelUsersQuery, GetChannelUsersQueryVariables>(client, GetChannelUsersDocument, variables, headers);
