'use client';

import _ from 'lodash';
import { useReducer } from 'react';

import type {
  GetUserPresenceQuery,
  GetUserPresenceQueryVariables,
} from '@/client/api/user_presence/generated/operations.user';
import { GetUserPresenceDocument } from '@/client/api/user_presence/generated/operations.user';
import { useGraphQLSubscription } from '@/client/core/hooks/use-graphql-subscription';
import { UserPresencePlatform } from '@/shared/graphql/schema-extensions/scalars/user-presence-platform';

type PresenceData = Omit<NonNullable<GetUserPresenceQuery['user_presence_by_pk']>, '__typename'>;

type PresenceState = {
  loading: boolean;
  error?: unknown;
} & { [T in UserPresencePlatform]?: PresenceData; };

export const useUserPresence = (id?: string) => {
  const [presence, setPresence] = useReducer(
    (previous: PresenceState, next: PresenceState) => (_.isEqual(previous, next) ? previous : next),
    { loading: true },
  );

  useGraphQLSubscription<GetUserPresenceQuery, GetUserPresenceQueryVariables>({
    ...(id != null
      ? {
        enabled: true,
        variables: {
          userId: id,
          platform: UserPresencePlatform.Web,
        },
      }
      : {
        enabled: false,
      }),
    query: GetUserPresenceDocument,
    onNext: (data) => {
      const updatedPresence = data.user_presence_by_pk;

      setPresence({ loading: false, web: updatedPresence ?? undefined });
    },
    onError: (error) => {
      setPresence({
        loading: false,
        error,
      });
    },
  });

  return presence;
};
