'use client';

import React, { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import {
  useRefreshChannelsUserHeartbeatMutation, useSetActiveChannelIdMutation,
  useUnsetActiveChannelIdMutation,
} from '@/client/api/channels_user/generated/operations.user';
import { type GraphQLSubscriptionOptions, useGraphQLSubscription } from '@/client/core/hooks/use-graphql-subscription';
import { dayjs } from '@/client/core/utils/date';
import {
  GetChannelUsersDocument,
  type GetChannelUsersQuery,
  type GetChannelUsersQueryVariables,
} from '@/client/features/calls/operations/generated/get-channel-users.user';
import { User_Role } from '@/generated/graphql/global-types.user';
import { wrapFetcher } from '@/shared/graphql/client';

const refreshChannelsUserHeartbeat = wrapFetcher(useRefreshChannelsUserHeartbeatMutation.fetcher);
const unsetActiveChannelId = wrapFetcher(useUnsetActiveChannelIdMutation.fetcher);
const setActiveChannelId = wrapFetcher(useSetActiveChannelIdMutation.fetcher);

interface UpdateProps {
  channelId: string;
}

interface RemoveProps {
  channelId: string;
}

interface SetProps {
  channelId: string;
}

// Method to update the "updated_at" column for "channels_user". This is for heartbeat purposes.
export const refreshPresence = async ({ channelId }: UpdateProps) => {
  const now = dayjs.utc().toISOString();

  await refreshChannelsUserHeartbeat({ channelId, now });
}

export const setPresence = async ({ channelId }: SetProps) => {
  await setActiveChannelId({ channelId });
}

export const removePresence = async ({ channelId }: RemoveProps): Promise<void> => {
  await unsetActiveChannelId({ channelId });
};

export interface ChannelUser {
  photoUrl?: string;
  handRaised: boolean;
  forceMuted: boolean;
  username: string;
  admin: boolean;
  connection: 'good' | 'low' | 'very-low';
}

interface ChannelUsersState {
  users?: Record<string, ChannelUser>;
  loading: boolean;
  error?: unknown;
}

interface ChannelUsersContextType {
  subscribeToChannel: (channelId: string) => void;
  unsubscribeFromChannel: (channelId: string) => void;
  getChannelUsers: (channelId: string) => ChannelUsersState;
}

const ChannelUsersContext = createContext<ChannelUsersContextType | undefined>(undefined);

type ChannelUsersAction =
  | { type: 'SUBSCRIBE'; channelId: string }
  | { type: 'UNSUBSCRIBE'; channelId: string }
  | { type: 'UPDATE_USERS'; channelId: string; users: Record<string, ChannelUser> }
  | { type: 'SET_ERROR'; channelId: string; error: unknown };

interface ChannelUsersProviderState {
  channels: Record<string, ChannelUsersState>;
  subscriptions: Record<string, number>;
}

const channelUsersReducer = (
  state: ChannelUsersProviderState,
  action: ChannelUsersAction,
): ChannelUsersProviderState => {
  switch (action.type) {
    case 'SUBSCRIBE':
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.channelId]: (state.subscriptions[action.channelId] || 0) + 1,
        },
      };
    case 'UNSUBSCRIBE': {
      const newSubscriptions = { ...state.subscriptions };
      newSubscriptions[action.channelId] = Math.max((newSubscriptions[action.channelId] || 0) - 1, 0);
      if (newSubscriptions[action.channelId] === 0) {
        delete newSubscriptions[action.channelId];
        const newChannels = { ...state.channels };
        delete newChannels[action.channelId];
        return { ...state, subscriptions: newSubscriptions, channels: newChannels };
      }
      return { ...state, subscriptions: newSubscriptions };
    }
    case 'UPDATE_USERS':
      return {
        ...state,
        channels: {
          ...state.channels,
          [action.channelId]: { loading: false, users: action.users },
        },
      };
    case 'SET_ERROR':
      return {
        ...state,
        channels: {
          ...state.channels,
          [action.channelId]: { loading: false, error: action.error },
        },
      };
    default:
      return state;
  }
};

export const ChannelUsersProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(channelUsersReducer, { channels: {}, subscriptions: {} });

  const subscribeToChannel = useCallback((channelId: string) => {
    dispatch({ type: 'SUBSCRIBE', channelId });
  }, []);

  const unsubscribeFromChannel = useCallback((channelId: string) => {
    dispatch({ type: 'UNSUBSCRIBE', channelId });
  }, []);

  const getChannelUsers = useCallback(
    (channelId: string): ChannelUsersState => state.channels[channelId] || { loading: true },
    [state.channels],
  );

  const contextValue: ChannelUsersContextType = useMemo(
    () => ({
      subscribeToChannel,
      unsubscribeFromChannel,
      getChannelUsers,
    }),
    [getChannelUsers, subscribeToChannel, unsubscribeFromChannel],
  );

  return (
    <ChannelUsersContext.Provider value={contextValue}>
      {children}
      {Object.keys(state.subscriptions).map((channelId) => (
        <ChannelSubscription key={channelId} channelId={channelId} dispatch={dispatch} />
      ))}
    </ChannelUsersContext.Provider>
  );
};

const ChannelSubscription: React.FC<{ channelId: string; dispatch: React.Dispatch<ChannelUsersAction> }> = ({
  channelId,
  dispatch,
}) => {
  const subscriptionOptions: GraphQLSubscriptionOptions<GetChannelUsersQuery, GetChannelUsersQueryVariables, true> = {
    query: GetChannelUsersDocument,
    useRealtimeSubscription: true,
    variables: { channelId },
    onNext: (data) => {
      const users: Record<string, ChannelUser> = {};

      for (const user of data.channels_user) {
        const publicUser = user.profile;

        if (publicUser == null) {
          continue;
        }
        users[user.user_id] = {
          photoUrl: publicUser.photo!,
          handRaised: user.hand_raised,
          forceMuted: user.force_muted,
          username: publicUser.username ?? 'Someone',
          admin: publicUser.role === User_Role.Team,
          connection:
            user.connection === 'good' || user.connection === 'low' || user.connection === 'very-low'
              ? user.connection
              : 'good',
        };
      }
      dispatch({ type: 'UPDATE_USERS', channelId, users });
    },
    onError: (error) => {
      dispatch({ type: 'SET_ERROR', channelId, error });
    },
  };

  useGraphQLSubscription(subscriptionOptions);

  return null;
};

export const useChannelUsers = (channelId?: string): ChannelUsersState => {
  const context = useContext(ChannelUsersContext);

  if (!context) {
    throw new Error('useChannelUsers must be used within a ChannelUsersProvider');
  }
  const { subscribeToChannel, unsubscribeFromChannel, getChannelUsers } = context;

  useEffect(() => {
    if (!channelId) {
      return;
    }
    subscribeToChannel(channelId);

    return () => {
      unsubscribeFromChannel(channelId);
    };
  }, [channelId, subscribeToChannel, unsubscribeFromChannel]);

  return useMemo(() => (channelId ? getChannelUsers(channelId) : { loading: true }), [channelId, getChannelUsers]);
};
