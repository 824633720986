// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { ClubDetailFragmentDoc } from '../../../../api/clubs/generated/operations.user';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetUserClubInfoQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;


export type GetUserClubInfoQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly clubs: ReadonlyArray<(
    { readonly __typename?: 'clubs' }
    & Pick<Types.Clubs, 'id' | 'name' | 'status' | 'url_name' | 'display_settings' | 'privacy' | 'lock_time' | 'unlock_time' | 'onboarding_requirements'>
    & { readonly schedule?: Types.Maybe<(
      { readonly __typename?: 'schedules' }
      & Pick<Types.Schedules, 'id' | 'timezone' | 'schedule'>
    )>, readonly membership: ReadonlyArray<(
      { readonly __typename?: 'clubs_members' }
      & Pick<Types.Clubs_Members, 'onboarded' | 'onboarding'>
    )> }
  )> }
);



export const GetUserClubInfoDocument = `
    query GetUserClubInfo($userId: String!) {
  clubs(order_by: [{created_at: desc, status: desc_nulls_last}]) {
    ...ClubDetail
  }
}
    ${ClubDetailFragmentDoc}`;

export const useGetUserClubInfoQuery = <
      TData = GetUserClubInfoQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserClubInfoQueryVariables,
      options?: UseQueryOptions<GetUserClubInfoQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserClubInfoQuery, TError, TData>(
      ['GetUserClubInfo', variables],
      fetcher<GetUserClubInfoQuery, GetUserClubInfoQueryVariables>(client, GetUserClubInfoDocument, variables, headers),
      options
    )};

useGetUserClubInfoQuery.getKey = (variables: GetUserClubInfoQueryVariables) => ['GetUserClubInfo', variables];

export const useInfiniteGetUserClubInfoQuery = <
      TData = GetUserClubInfoQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserClubInfoQueryVariables,
      options?: UseInfiniteQueryOptions<GetUserClubInfoQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetUserClubInfoQuery, TError, TData>(
      ['GetUserClubInfo.infinite', variables],
      (metaData) => fetcher<GetUserClubInfoQuery, GetUserClubInfoQueryVariables>(client, GetUserClubInfoDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetUserClubInfoQuery.getKey = (variables: GetUserClubInfoQueryVariables) => ['GetUserClubInfo.infinite', variables];


useGetUserClubInfoQuery.fetcher = (client: GraphQLClient, variables: GetUserClubInfoQueryVariables, headers?: RequestInit['headers']) => fetcher<GetUserClubInfoQuery, GetUserClubInfoQueryVariables>(client, GetUserClubInfoDocument, variables, headers);
