// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { RoomDetailFragmentDoc } from '../../../api/rooms/generated/operations.user';
import { ClubDetailFragmentDoc } from '../../../api/clubs/generated/operations.user';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetClubRoomsQueryVariables = Types.Exact<{
  clubIds: ReadonlyArray<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type GetClubRoomsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms: ReadonlyArray<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'association_id' | 'color' | 'id' | 'kind' | 'name' | 'status' | 'tagline' | 'url_name' | 'owner_id' | 'lock_time' | 'unlock_time' | 'feedback_question' | 'clipboard_prompt' | 'sort_order' | 'icon_url' | 'host_user_ids' | 'privacy' | 'club_id'>
    & { readonly club: (
      { readonly __typename?: 'clubs' }
      & Pick<Types.Clubs, 'id' | 'name' | 'url_name' | 'display_settings'>
      & { readonly members: ReadonlyArray<(
        { readonly __typename?: 'clubs_members' }
        & Pick<Types.Clubs_Members, 'onboarded'>
      )> }
    ), readonly channel?: Types.Maybe<(
      { readonly __typename?: 'channels' }
      & Pick<Types.Channels, 'id' | 'association_id' | 'user_status_platform_states'>
    )>, readonly breakouts: ReadonlyArray<(
      { readonly __typename?: 'breakouts' }
      & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'room_id' | 'channel_id'>
      & { readonly room?: Types.Maybe<(
        { readonly __typename?: 'rooms' }
        & Pick<Types.Rooms, 'club_id' | 'kind'>
      )> }
    )>, readonly rooms_schedule?: Types.Maybe<(
      { readonly __typename?: 'schedules' }
      & Pick<Types.Schedules, 'id' | 'timezone' | 'schedule'>
    )> }
  )> }
);

export type GetClubDetailQueryVariables = Types.Exact<{
  urlName: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type GetClubDetailQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly clubs: ReadonlyArray<(
    { readonly __typename?: 'clubs' }
    & Pick<Types.Clubs, 'id' | 'name' | 'status' | 'url_name' | 'display_settings' | 'privacy' | 'lock_time' | 'unlock_time' | 'onboarding_requirements'>
    & { readonly schedule?: Types.Maybe<(
      { readonly __typename?: 'schedules' }
      & Pick<Types.Schedules, 'id' | 'timezone' | 'schedule'>
    )>, readonly membership: ReadonlyArray<(
      { readonly __typename?: 'clubs_members' }
      & Pick<Types.Clubs_Members, 'onboarded' | 'onboarding'>
    )> }
  )> }
);

export type GetClubDetailSubscriptionQueryVariables = Types.Exact<{
  urlName: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type GetClubDetailSubscriptionQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly clubs: ReadonlyArray<(
    { readonly __typename?: 'clubs' }
    & Pick<Types.Clubs, 'id' | 'name' | 'status' | 'url_name' | 'display_settings' | 'privacy' | 'lock_time' | 'unlock_time' | 'onboarding_requirements'>
    & { readonly schedule?: Types.Maybe<(
      { readonly __typename?: 'schedules' }
      & Pick<Types.Schedules, 'id' | 'timezone' | 'schedule'>
    )>, readonly membership: ReadonlyArray<(
      { readonly __typename?: 'clubs_members' }
      & Pick<Types.Clubs_Members, 'onboarded' | 'onboarding'>
    )> }
  )> }
);

export type GetValidUserClubDetailsQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  clubUrlName: Types.Scalars['String']['input'];
}>;


export type GetValidUserClubDetailsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly clubs: ReadonlyArray<(
    { readonly __typename?: 'clubs' }
    & Pick<Types.Clubs, 'id' | 'name' | 'status' | 'url_name' | 'display_settings' | 'privacy' | 'lock_time' | 'unlock_time' | 'onboarding_requirements'>
    & { readonly schedule?: Types.Maybe<(
      { readonly __typename?: 'schedules' }
      & Pick<Types.Schedules, 'id' | 'timezone' | 'schedule'>
    )>, readonly membership: ReadonlyArray<(
      { readonly __typename?: 'clubs_members' }
      & Pick<Types.Clubs_Members, 'onboarded' | 'onboarding'>
    )> }
  )> }
);

export type LiveEventSummaryFragment = (
  { readonly __typename?: 'live_events' }
  & Pick<Types.Live_Events, 'ends_at' | 'id' | 'name' | 'promo_image_url' | 'room_id' | 'starts_at' | 'tagline'>
  & { readonly schedule?: Types.Maybe<(
    { readonly __typename?: 'schedules' }
    & Pick<Types.Schedules, 'timezone' | 'schedule'>
  )> }
);

export type GetUpcomingLiveEventsQueryVariables = Types.Exact<{
  startTime: Types.Scalars['timestamptz']['input'];
  endTime: Types.Scalars['timestamptz']['input'];
  clubUrlName: Types.Scalars['String']['input'];
}>;


export type GetUpcomingLiveEventsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly live_events: ReadonlyArray<(
    { readonly __typename?: 'live_events' }
    & Pick<Types.Live_Events, 'ends_at' | 'id' | 'name' | 'promo_image_url' | 'room_id' | 'starts_at' | 'tagline'>
    & { readonly schedule?: Types.Maybe<(
      { readonly __typename?: 'schedules' }
      & Pick<Types.Schedules, 'timezone' | 'schedule'>
    )> }
  )> }
);


export const LiveEventSummaryFragmentDoc = `
    fragment LiveEventSummary on live_events {
  ends_at
  id
  name
  promo_image_url
  room_id
  starts_at
  tagline
  schedule {
    timezone
    schedule
  }
}
    `;
export const GetClubRoomsDocument = `
    query GetClubRooms($clubIds: [uuid!]!, $userId: String!) {
  rooms(
    where: {club_id: {_in: $clubIds}}
    order_by: {sort_order: asc, name: asc, unlock_time: asc}
  ) {
    ...RoomDetail
  }
}
    ${RoomDetailFragmentDoc}`;

export const useGetClubRoomsQuery = <
      TData = GetClubRoomsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetClubRoomsQueryVariables,
      options?: UseQueryOptions<GetClubRoomsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetClubRoomsQuery, TError, TData>(
      ['GetClubRooms', variables],
      fetcher<GetClubRoomsQuery, GetClubRoomsQueryVariables>(client, GetClubRoomsDocument, variables, headers),
      options
    )};

useGetClubRoomsQuery.getKey = (variables: GetClubRoomsQueryVariables) => ['GetClubRooms', variables];

export const useInfiniteGetClubRoomsQuery = <
      TData = GetClubRoomsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetClubRoomsQueryVariables,
      options?: UseInfiniteQueryOptions<GetClubRoomsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetClubRoomsQuery, TError, TData>(
      ['GetClubRooms.infinite', variables],
      (metaData) => fetcher<GetClubRoomsQuery, GetClubRoomsQueryVariables>(client, GetClubRoomsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetClubRoomsQuery.getKey = (variables: GetClubRoomsQueryVariables) => ['GetClubRooms.infinite', variables];


useGetClubRoomsQuery.fetcher = (client: GraphQLClient, variables: GetClubRoomsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetClubRoomsQuery, GetClubRoomsQueryVariables>(client, GetClubRoomsDocument, variables, headers);

export const GetClubDetailDocument = `
    query GetClubDetail($urlName: String!, $userId: String!) {
  clubs(where: {url_name: {_eq: $urlName}}) {
    ...ClubDetail
  }
}
    ${ClubDetailFragmentDoc}`;

export const useGetClubDetailQuery = <
      TData = GetClubDetailQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetClubDetailQueryVariables,
      options?: UseQueryOptions<GetClubDetailQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetClubDetailQuery, TError, TData>(
      ['GetClubDetail', variables],
      fetcher<GetClubDetailQuery, GetClubDetailQueryVariables>(client, GetClubDetailDocument, variables, headers),
      options
    )};

useGetClubDetailQuery.getKey = (variables: GetClubDetailQueryVariables) => ['GetClubDetail', variables];

export const useInfiniteGetClubDetailQuery = <
      TData = GetClubDetailQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetClubDetailQueryVariables,
      options?: UseInfiniteQueryOptions<GetClubDetailQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetClubDetailQuery, TError, TData>(
      ['GetClubDetail.infinite', variables],
      (metaData) => fetcher<GetClubDetailQuery, GetClubDetailQueryVariables>(client, GetClubDetailDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetClubDetailQuery.getKey = (variables: GetClubDetailQueryVariables) => ['GetClubDetail.infinite', variables];


useGetClubDetailQuery.fetcher = (client: GraphQLClient, variables: GetClubDetailQueryVariables, headers?: RequestInit['headers']) => fetcher<GetClubDetailQuery, GetClubDetailQueryVariables>(client, GetClubDetailDocument, variables, headers);

export const GetClubDetailSubscriptionDocument = `
    query GetClubDetailSubscription($urlName: String!, $userId: String!) {
  clubs(where: {url_name: {_eq: $urlName}}) {
    ...ClubDetail
  }
}
    ${ClubDetailFragmentDoc}`;

export const useGetClubDetailSubscriptionQuery = <
      TData = GetClubDetailSubscriptionQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetClubDetailSubscriptionQueryVariables,
      options?: UseQueryOptions<GetClubDetailSubscriptionQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetClubDetailSubscriptionQuery, TError, TData>(
      ['GetClubDetailSubscription', variables],
      fetcher<GetClubDetailSubscriptionQuery, GetClubDetailSubscriptionQueryVariables>(client, GetClubDetailSubscriptionDocument, variables, headers),
      options
    )};

useGetClubDetailSubscriptionQuery.getKey = (variables: GetClubDetailSubscriptionQueryVariables) => ['GetClubDetailSubscription', variables];

export const useInfiniteGetClubDetailSubscriptionQuery = <
      TData = GetClubDetailSubscriptionQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetClubDetailSubscriptionQueryVariables,
      options?: UseInfiniteQueryOptions<GetClubDetailSubscriptionQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetClubDetailSubscriptionQuery, TError, TData>(
      ['GetClubDetailSubscription.infinite', variables],
      (metaData) => fetcher<GetClubDetailSubscriptionQuery, GetClubDetailSubscriptionQueryVariables>(client, GetClubDetailSubscriptionDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetClubDetailSubscriptionQuery.getKey = (variables: GetClubDetailSubscriptionQueryVariables) => ['GetClubDetailSubscription.infinite', variables];


useGetClubDetailSubscriptionQuery.fetcher = (client: GraphQLClient, variables: GetClubDetailSubscriptionQueryVariables, headers?: RequestInit['headers']) => fetcher<GetClubDetailSubscriptionQuery, GetClubDetailSubscriptionQueryVariables>(client, GetClubDetailSubscriptionDocument, variables, headers);

export const GetValidUserClubDetailsDocument = `
    query GetValidUserClubDetails($userId: String!, $clubUrlName: String!) {
  clubs(where: {url_name: {_eq: $clubUrlName}}) {
    ...ClubDetail
  }
}
    ${ClubDetailFragmentDoc}`;

export const useGetValidUserClubDetailsQuery = <
      TData = GetValidUserClubDetailsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetValidUserClubDetailsQueryVariables,
      options?: UseQueryOptions<GetValidUserClubDetailsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetValidUserClubDetailsQuery, TError, TData>(
      ['GetValidUserClubDetails', variables],
      fetcher<GetValidUserClubDetailsQuery, GetValidUserClubDetailsQueryVariables>(client, GetValidUserClubDetailsDocument, variables, headers),
      options
    )};

useGetValidUserClubDetailsQuery.getKey = (variables: GetValidUserClubDetailsQueryVariables) => ['GetValidUserClubDetails', variables];

export const useInfiniteGetValidUserClubDetailsQuery = <
      TData = GetValidUserClubDetailsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetValidUserClubDetailsQueryVariables,
      options?: UseInfiniteQueryOptions<GetValidUserClubDetailsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetValidUserClubDetailsQuery, TError, TData>(
      ['GetValidUserClubDetails.infinite', variables],
      (metaData) => fetcher<GetValidUserClubDetailsQuery, GetValidUserClubDetailsQueryVariables>(client, GetValidUserClubDetailsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetValidUserClubDetailsQuery.getKey = (variables: GetValidUserClubDetailsQueryVariables) => ['GetValidUserClubDetails.infinite', variables];


useGetValidUserClubDetailsQuery.fetcher = (client: GraphQLClient, variables: GetValidUserClubDetailsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetValidUserClubDetailsQuery, GetValidUserClubDetailsQueryVariables>(client, GetValidUserClubDetailsDocument, variables, headers);

export const GetUpcomingLiveEventsDocument = `
    query getUpcomingLiveEvents($startTime: timestamptz!, $endTime: timestamptz!, $clubUrlName: String!) {
  live_events(
    where: {starts_at: {_gte: $startTime}, ends_at: {_lt: $endTime}, room: {club: {url_name: {_eq: $clubUrlName}}}, status: {_nin: [cancelled, hidden]}}
    order_by: {starts_at: asc}
    limit: 7
  ) {
    ...LiveEventSummary
  }
}
    ${LiveEventSummaryFragmentDoc}`;

export const useGetUpcomingLiveEventsQuery = <
      TData = GetUpcomingLiveEventsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUpcomingLiveEventsQueryVariables,
      options?: UseQueryOptions<GetUpcomingLiveEventsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUpcomingLiveEventsQuery, TError, TData>(
      ['getUpcomingLiveEvents', variables],
      fetcher<GetUpcomingLiveEventsQuery, GetUpcomingLiveEventsQueryVariables>(client, GetUpcomingLiveEventsDocument, variables, headers),
      options
    )};

useGetUpcomingLiveEventsQuery.getKey = (variables: GetUpcomingLiveEventsQueryVariables) => ['getUpcomingLiveEvents', variables];

export const useInfiniteGetUpcomingLiveEventsQuery = <
      TData = GetUpcomingLiveEventsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUpcomingLiveEventsQueryVariables,
      options?: UseInfiniteQueryOptions<GetUpcomingLiveEventsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetUpcomingLiveEventsQuery, TError, TData>(
      ['getUpcomingLiveEvents.infinite', variables],
      (metaData) => fetcher<GetUpcomingLiveEventsQuery, GetUpcomingLiveEventsQueryVariables>(client, GetUpcomingLiveEventsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetUpcomingLiveEventsQuery.getKey = (variables: GetUpcomingLiveEventsQueryVariables) => ['getUpcomingLiveEvents.infinite', variables];


useGetUpcomingLiveEventsQuery.fetcher = (client: GraphQLClient, variables: GetUpcomingLiveEventsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetUpcomingLiveEventsQuery, GetUpcomingLiveEventsQueryVariables>(client, GetUpcomingLiveEventsDocument, variables, headers);
