// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, useMutation, type UseQueryOptions, type UseInfiniteQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetUserPresenceQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  platform: Types.Scalars['String']['input'];
}>;


export type GetUserPresenceQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly user_presence_by_pk?: Types.Maybe<(
    { readonly __typename?: 'user_presence' }
    & Pick<Types.User_Presence, 'online'>
  )> }
);

export type UpdateSelfUserPresenceMutationVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  platform: Types.Scalars['user_presence_platform']['input'];
  online: Types.Scalars['Boolean']['input'];
}>;


export type UpdateSelfUserPresenceMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly insert_user_presence_one?: Types.Maybe<(
    { readonly __typename?: 'user_presence' }
    & Pick<Types.User_Presence, 'online'>
  )> }
);



export const GetUserPresenceDocument = `
    query GetUserPresence($userId: String!, $platform: String!) {
  user_presence_by_pk(user_id: $userId, platform: $platform) {
    online
  }
}
    `;

export const useGetUserPresenceQuery = <
      TData = GetUserPresenceQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserPresenceQueryVariables,
      options?: UseQueryOptions<GetUserPresenceQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserPresenceQuery, TError, TData>(
      ['GetUserPresence', variables],
      fetcher<GetUserPresenceQuery, GetUserPresenceQueryVariables>(client, GetUserPresenceDocument, variables, headers),
      options
    )};

useGetUserPresenceQuery.getKey = (variables: GetUserPresenceQueryVariables) => ['GetUserPresence', variables];

export const useInfiniteGetUserPresenceQuery = <
      TData = GetUserPresenceQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserPresenceQueryVariables,
      options?: UseInfiniteQueryOptions<GetUserPresenceQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetUserPresenceQuery, TError, TData>(
      ['GetUserPresence.infinite', variables],
      (metaData) => fetcher<GetUserPresenceQuery, GetUserPresenceQueryVariables>(client, GetUserPresenceDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetUserPresenceQuery.getKey = (variables: GetUserPresenceQueryVariables) => ['GetUserPresence.infinite', variables];


useGetUserPresenceQuery.fetcher = (client: GraphQLClient, variables: GetUserPresenceQueryVariables, headers?: RequestInit['headers']) => fetcher<GetUserPresenceQuery, GetUserPresenceQueryVariables>(client, GetUserPresenceDocument, variables, headers);

export const UpdateSelfUserPresenceDocument = `
    mutation UpdateSelfUserPresence($userId: String!, $platform: user_presence_platform!, $online: Boolean!) {
  insert_user_presence_one(
    object: {user_id: $userId, online: $online, platform: $platform}
    on_conflict: {constraint: user_presence_pkey, update_columns: [online]}
  ) {
    online
  }
}
    `;

export const useUpdateSelfUserPresenceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateSelfUserPresenceMutation, TError, UpdateSelfUserPresenceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateSelfUserPresenceMutation, TError, UpdateSelfUserPresenceMutationVariables, TContext>(
      ['UpdateSelfUserPresence'],
      (variables?: UpdateSelfUserPresenceMutationVariables) => fetcher<UpdateSelfUserPresenceMutation, UpdateSelfUserPresenceMutationVariables>(client, UpdateSelfUserPresenceDocument, variables, headers)(),
      options
    )};

useUpdateSelfUserPresenceMutation.getKey = () => ['UpdateSelfUserPresence'];


useUpdateSelfUserPresenceMutation.fetcher = (client: GraphQLClient, variables: UpdateSelfUserPresenceMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateSelfUserPresenceMutation, UpdateSelfUserPresenceMutationVariables>(client, UpdateSelfUserPresenceDocument, variables, headers);
